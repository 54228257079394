<template>
    <div :class="{'error': quoteData.errors.travelZone}">

        <!-- Error text -->
        <span id="error-travel-zone" :v-if="quoteData.errors.travelZone" class="input-error">{{ quoteData.errors.travelZone }}</span>

        <div class="columns">
            <div class="input-label title is-6 column">
                <div>Choose Your Area</div>
                <b-button style="float: right; color: #494949;" :type="'is-ghost'" size="is-small" @click="showInfo = !showInfo" class="hvr-icon-bob is-hidden-desktop"><i class="fas fa-question-circle hvr-icon"/></b-button>
            </div>

            <!--<box-radio-selector :options="$c2g.policyType" v-model="quoteData.policyType"/>-->

            <div class="column is-two-fifths">
                <b-select expanded v-model="quoteData.travelZone">
                    <option v-for="(v, k) in $c2g.area" :key="k" :value="k" :selected="k === 'euro'">{{ v }}</option>
                </b-select>
            </div>

            <div class="column is-hidden-touch">
                <b-button style="float: right; color: #494949;" :type="'is-ghost'" size="is-small" @click="showInfo = !showInfo" class="hvr-icon-bob"><i class="fas fa-question-circle hvr-icon"/></b-button>
            </div>

        </div>
        <transition appear enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster">
            <div v-if="showInfo" class="box">
                <strong>United Kingdom (trips must be a minimum of 2 nights):</strong><br />
                England, Scotland, Wales, Northern Ireland and the Isle of Man.
                <br /><br />

                <strong>Europe:</strong><br />
                Åland Islands, Albania, Algeria, Andorra, Armenia, Austria, Azerbaijan, Belarus, Belgium,
                Bosnia and Herzegovina, Bulgaria, Corsica, Croatia, Cyprus, Czech Republic, Denmark, Egypt, Estonia,
                Faro Islands, Finland, France, Georgia, Germany, Gibraltar, Greece, Guernsey, Holy See (Vatican City
                State), Hungary, Iceland, Israel, Italy, Jersey, Kosovo, Latvia, Liechtenstein, Lithuania, Luxembourg,
                Macedonia, Madeira, Malta, Mediterranean Islands, Moldova, Monaco, Montenegro, Morocco, Netherlands,
                Norway, Poland, Portugal, Republic of Ireland, Romania, San Marino, Sardinia, Serbia, Slovakia, Slovenia,
                Spain, Svalbard and Jan Mayen, Sweden, Switzerland, Turkey, Tunisia, The Azores, The Canary Islands,
                The Channel Islands, Ukraine and territories formally known as USSR, west of the Ural Mountains.
                <p><em>By selecting this area of cover, you are also covered for destinations in the following territory areas: United Kingdom.</em></p>
                <br /><br />

              <strong>Australia and New Zealand</strong><br />
              Australia and New Zealand<br>
              <p><em>By selecting this area of cover, you are also covered for destinations in the following territory areas: United Kingdom and Europe.</em></p>
              <br /><br />

                <strong>The Caribbean</strong><br />
                Antigua and Barbuda, Anguilla, Aruba, The Bahamas, Barbados, British Virgin Islands, Cayman Islands,
                Cuba, Dominica, Dominican Republic, Grenada, Guadeloupe, Haiti, Jamaica, Martinique, Montserrat,
                Navassa Island, Netherland Antilles, Puerto Rico, Saint Kitts and Nevis, Saint Lucia, Saint Vincent and
                the Grenadines, Trinidad and Tobago, Turks and Caicos Islands, Virgin Islands.
                <br /><br />

                <strong>Worldwide (excluding USA, Canada & The Caribbean)</strong><br />
                Worldwide excluding United States of America, Canada and The Caribbean.
              <p><em>By selecting this area of cover, you are also covered for destinations in the following territory areas: United Kingdom, Europe and Australia and New Zealand.</em></p>
                <br /><br />

                <strong>Worldwide (including USA, Canada & The Caribbean)</strong><br />
                Worldwide.
              <p><em>By selecting this area of cover, you are also covered for destinations in the following territory areas: United Kingdom, Europe, Australia and New Zealand and Worldwide excluding USA, Canada and Caribbean.</em></p>

                <!--<p>Find your travel area by specifying your country below:</p>
                <input type="text" class="input is-primary" v-model="searchQuery" placeholder="eg. 'England'">

                <div v-if="searchQuery.length > 1 && Object.keys(getAreas(searchQuery)).length > 0" id="search-results" class="box">
                    <div v-for="area in getAreas(searchQuery)" :key="area.id">

                        <div class="columns">
                            <div class="column"> {{ area.country }} </div>
                            <div class="column"> {{ area.area }} </div>
                            <div class="column"> <button class="button is-primary is-small" @click.prevent="select(area.area)">Select</button> </div>
                        </div>
                    </div>
                </div>-->
            </div>
        </transition>

        <!--<box-radio-selector :col-size="'is-half'" :options="$c2g.area" v-model="quoteData.travelZone"/>-->

    </div>
</template>

<script>
    //import BoxRadioSelector from "../../common/form/BoxRadioSelector";

    export default {
        name: "QuoteAreaSelector",
        data() {
            return {
                showInfo: false,
                travelAreas: null,
                searchQuery: ''
            }
        },
        methods: {
            // Get an array of areas
            getAreas() {
                return this.travelAreas.filter(a => {
                    return a.country.toLowerCase().includes(this.searchQuery.toLowerCase());
                });
            },
            // Select a county from the search
            select(value) {
                if(value === 'UK') value = 'uk';
                if(value === 'Europe') value = 'euro';
                if(value === 'Worldwide Excl. USA, Canada, and Caribbean') value = 'wwexc';
                if(value === 'Worldwide Incl. USA, Canada, and Caribbean') value = 'wwinc';
                if(value === 'Australia and New Zealand') value = 'ausnz';
                this.showInfo = false;
                this.searchQuery = '';
                this.quoteData.travelZone = value;
            }
        },
        props: ['quoteData'],
        watch: {
            'quoteData.travelZone'() {
                this.quoteData.errors.travelZone = '';
            }
        },
        components: {
            //BoxRadioSelector
        },
        created() {
            // Query API for travel areas
            this.$c2g.getTravelAreas().then((response) => {
                this.travelAreas = JSON.parse(response.data);
            });
        }
    }
</script>

<style scoped>
    .input-label {
        margin-top: 10px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .input {
        margin-top: 5px;
    }
    #search-results {
        margin-top: 5px;
    }
    .error {
        border: 2px solid red;
        border-radius: 5px;
        padding: 5px 10px 20px 10px;
    }
</style>