<template>
    <div :class="{'error': quoteData.errors.startDate || quoteData.errors.endDate}">

        <div class="columns">

            <div class="input-label title is-6 column">
                <div>When Are You Going?</div>
                <b-button style="float: right; color: #494949;" :type="'is-ghost'" size="is-small" @click="showInfo = !showInfo" class="hvr-icon-bob is-hidden-desktop"><i class="fas fa-question-circle hvr-icon"/></b-button>
            </div>

            <!-- Start Date -->
            <div class="column is-one-fifth">
                <span :v-if="quoteData.errors.startDate !== ''" class="input-error">{{ quoteData.errors.startDate }}</span>
                <b-datepicker :class="startDate" icon="calendar-today" v-model="datesOb[0]"
                              :min-date="$moment().subtract(1, 'days').toDate()" :max-date="maxStartDate"
                              editable :date-formatter="(date) => date.toLocaleDateString('en-GB')" nearby-selectable-month-days
                              :date-parser="(date) => new Date(date.split('/')[2], date.split('/')[1]-1, date.split('/')[0])">
                </b-datepicker>
            </div>
            <!-- End Date -->
            <div class="column is-one-fifth">
                <span :v-if="quoteData.errors.endDate !== ''" class="input-error">{{ quoteData.errors.endDate }}</span>
                <b-datepicker :class="endDate" icon="calendar-today" v-model="datesOb[1]"
                              :min-date="datesOb[0]" nearby-selectable-month-days :max-date="maxEndDate"
                              editable :disabled="quoteData.policyType?.includes('AT')" :date-formatter="(date) => date.toLocaleDateString('en-GB')"
                              :date-parser="(date) => new Date(date.split('/')[2], date.split('/')[1]-1, date.split('/')[0])">
                </b-datepicker>
            </div>

            <div class="column is-one-fith is-hidden-touch">
                <b-button style="float: right; color: #494949;" :type="'is-ghost'" size="is-small" @click="showInfo = !showInfo" class="hvr-icon-bob"><i class="fas fa-question-circle hvr-icon"/></b-button>
            </div>


        </div>

        <!-- Date info -->
        <transition appear enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster">
            <div v-if="showInfo" class="box">
                <p><strong>Single Trip and Longstay Policies</strong>: Please tell us the date you are leaving the United Kingdom. The start date and end date of the policy should correspond with the dates of travel. Cancellation cover will become effective the date the premium is paid, and the policy is issued.</p>
                <br>
                <p><strong>Annual Multi-Trip Policies</strong>: Choosing today's date will ensure you have cancellation cover in place from today, although you can select any date up to 60 days in advance.  </p>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "QuoteDatesSelector",
        data() {
            return {
                showInfo: false,
                startDateValid: true,
                endDateValid: false
            }
        },
        props: {
            dates: { type: Array, required: true },
            quoteData: { type: Object, required: true }
        },
        model: {
            prop: 'dates'
        },
        methods: {
            judgeDates() {
                if (this.quoteData.policyType?.includes('AT')) {
                    var enddate = this.$moment(this.datesOb[0]).add('1', 'years').subtract('1', 'days').toDate();
                    this.datesOb[1] = enddate;
                    this.quoteData.duration = this.$moment(this.datesOb[1]).diff(this.$moment(this.datesOb[0]), 'days') + 1;
                    this.endDateValid = true;
                }
            },
            validationMessages() {
                const futureStartDate = this.$moment(this.datesOb[0]).diff(this.$moment(), 'days') + 1;

                if(this.quoteData.policyType === 'ST' && this.quoteData.duration <= 1 && this.quoteData.travelZone === 'uk' && this.quoteData.duration) {
                    this.endDateValid = false;
                    this.quoteData.errors.endDate = 'Unfortunately we are unable to provide a quote as our Covered2go UK cover only provides protection for durations of two nights or more.';
                } else if (this.quoteData.policyType === 'LS' && this.quoteData.duration > 558) {
                    this.endDateValid = false;
                    this.quoteData.errors.endDate = 'Unfortunately we are unable to provide a quote as our Covered2go cover only provides protection for durations of 558 days or less.';
                } else if (this.quoteData.policyType === 'ST' && this.quoteData.duration > 729) {
                    this.startDateValid = false;
                    this.quoteData.errors.startDate = 'Unfortunately we are unable to provide a quote as our Covered2go cover only provides protection for trips that start and end within two years from now. Please call our team on 0333 400 7745.';
                } else if (this.quoteData.policyType === 'ST' && this.quoteData.duration > 365) {
                    this.endDateValid = false;
                    this.quoteData.errors.startDate = 'Unfortunately we are unable to provide a quote as our Covered2go cover only provides protection for trips that end within a year from the start date. Please call our team on 0333 400 7745.';
                } else if (futureStartDate < 1) {
                    this.startDateValid = false;
                    this.quoteData.errors.startDate = 'Please enter a valid departure date.';
                } else if (this.quoteData.policyType === 'LS' && this.quoteData.duration > 558) {
                    this.startDateValid = false;
                    this.quoteData.errors.startDate = 'Unfortunately we are unable to provide a quote as our Covered2go cover only provides protection for trips that start within 588 days from now. Please call our team on 0333 400 7745.';
                } else {
                    this.startDateValid = true;
                    this.endDateValid = true;
                }

                // Clear errors
                if(this.startDateValid && this.endDateValid) {
                    this.quoteData.errors.startDate = '';
                    this.quoteData.errors.endDate = '';
                }
            }
        },
        watch: {
            // On policy type change reset dates
            'quoteData.policyType'() {
                this.quoteData.duration = 0;
                this.endDateValid = false;

                this.datesOb[0] = new Date(new Date().setHours(0,0,0,0));
                this.datesOb.splice(1, 1);

                this.judgeDates()
                this.validationMessages();
            },
            'quoteData.travelZone'() {
                this.validationMessages();
            },
            datesOb() {
                this.judgeDates();
                // Validate dates
                this.startDateValid = !!this.dates[0];
                this.endDateValid = !!this.dates[1];
                this.quoteData.duration = this.$moment(this.datesOb[1]).diff(this.$moment(this.datesOb[0]), 'days') + 1;

                this.validationMessages();
            }
        },
        computed: {
            datesOb: {
                get() {
                    return this.dates;
                },
                set(v) {
                    this.$emit('input', v);
                }
            },
            // CSS date class
            startDate() {
                return { 'is-valid': this.startDateValid };
            },
            endDate() {
                return { 'is-valid': this.endDateValid && this.quoteData.policyType !== 'AT' };
            },
            maxStartDate() {
                if (typeof this.quoteData.policyType == "undefined") {
                    return this.datesOb[0];
                }
                if (this.quoteData.policyType.includes('AT')) {
                    return this.$moment(new Date(new Date().setHours(0,0,0,0))).add('60', 'days').toDate();
                }
                if (this.quoteData.policyType.includes('ST') || this.quoteData.policyType.includes('LS')) {
                    return this.$moment(new Date(new Date().setHours(0,0,0,0))).add('730', 'days').toDate();
                }
                return this.datesOb[0];
            },
            maxEndDate() {
                if (typeof this.quoteData.policyType == "undefined") {
                    return undefined;
                }
                if (this.quoteData.policyType.includes('AT')) {
                    return this.$moment(this.datesOb[0]).add('1', 'years').subtract('1', 'days').toDate();
                }
                return undefined;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .column {
        padding-bottom: 0;
    }
    .input-label {
        margin-top: 10px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .box {
        margin-top: 5px;
    }
    // Date valid signifier
    ::v-deep.is-valid {
        input {
            background-color: $c2g_orange;
            border-color: $c2g_orange;
            color: white;
        }
        .icon {
            color: white !important;
        }
        .mdi-chevron-right, .mdi-chevron-left {
            color: #F99131 !important;
        }
    }
    .error {
        border: 2px solid red;
        border-radius: 5px;
        padding: 0 10px 20px 10px;
    }
</style>