<template>
  <div class="column">
    <div v-if="!quoteData.formLoading" id="quote-form" class="box b-shdw-3">
      <div class="text-center">
        <h4 class="title">Step 1: Get a Travel Insurance Quote</h4>
      </div>
      <div class="text-center message">
        <h5 class="">Important Information:</h5>
        <p>
          <b>For your cover to be valid, you</b>, and anyone you would like named on your policy must:
          <ul>
            <li>
              <img class="tick-img" src="/img/tick-icon.png"/>
              Be a resident of the UK or Channel Islands
            </li>
            <li>
              <img class="tick-img" src="/img/tick-icon.png"/>
              Have your trip starting and returning to the UK or Channel Islands
            </li>
            <li>
              <img class="tick-img" src="/img/tick-icon.png"/>
              Have a permanent residential address in the UK or Channel Islands
            </li>
            <li>
              <img class="tick-img" src="/img/tick-icon.png"/>
              Have unrestricted right of entry to the UK or Channel Islands
            </li>
          </ul>
        </p>
      </div>
      <hr>

      <!-- Message -->
      <!-- <div class="alert-important" v-if="content.top_page_content !== '<br>'">
          <b-message type="is-danger">
              <p v-html="content.top_page_content"></p>
          </b-message>
      </div> -->

      <!-- Alert -->
      <div v-if="policySystemErrors" class="notification is-danger">
        <p>{{ policyCustomerErrors }}</p>
      </div>

      <form @submit.prevent="quoteFn">
        <!-- Policy Type -->
        <quote-policy-type-selector :quote-data="quoteData"/>
        <hr>
        <!-- Destination -->
        <quote-area-selector :quote-data="quoteData"/>
        <hr>
        <!-- Trip Duration -->
        <quote-dates-selector v-model="quoteData.dates" :quote-data="quoteData"/>
        <hr>
        <!-- Relationship -->
        <quote-relationship-selector :quote-data="quoteData"/>
        <hr>
        <!-- Passengers amount and dob selector -->
        <quote-passengers :quote-data="quoteData"/>
        <hr v-if="quoteData.paxAmount > 0">
        <!-- Student Cover -->
        <!-- <quote-student-cover v-if="quoteData.relationship === 'family'" :quote-data="quoteData"/> -->
        <!-- AP -->
        <quote-activity-selector :quote-data="quoteData"/>
        <hr>
        <!-- Endorsements -->
        <quote-endorsements v-model="quoteData.endorsements" :quote-data="quoteData"/>
        <hr>
        <!-- The total cost of the trip -->
        <quote-total-trip-cost v-if="quoteData.policyType?.includes('CR')" :quote-data="quoteData"/>
        <!-- Medical Screening Number -->
        <!--<quote-medical-screening-number :quote-data="quoteData"/>
        <hr>-->
        <!-- Promo code -->
        <quote-promo-code-input v-if="!this.affiliation || (this.affiliation && (this.affiliation.toLowerCase().includes('moneyfacts')
                || this.affiliation.toLowerCase().includes('shannon')
                || this.affiliation.toLowerCase().includes('jasmine')
                || this.affiliation.toLowerCase().includes('matthew')
                || this.affiliation.toLowerCase().includes('lynn')))" :quote-data="quoteData"/>
        <hr v-if="!this.affiliation || (this.affiliation && (this.affiliation.toLowerCase().includes('moneyfacts')
                || this.affiliation.toLowerCase().includes('shannon')
                || this.affiliation.toLowerCase().includes('jasmine')
                || this.affiliation.toLowerCase().includes('matthew')
                || this.affiliation.toLowerCase().includes('lynn')))">
        <!-- Email -->
        <quote-email :quote-data="quoteData"/>
        <hr>
        <!-- Marketing tick box -->
        <b-checkbox v-model="quoteData.marketing">
          We would love to stay in touch with you, but only to send useful and relevant information including discounts
          and offers - please tick this box to allow us to. Thank you.
        </b-checkbox>
        <hr>
        <button :class="{'is-loading':quoteData.formLoading}"
                class="button is-primary is-medium is-fullwidth hvr-icon-forward b-shdw-1">
          Get Quote <i class="fa fa-chevron-circle-right hvr-icon"/>
        </button>
      </form>
    </div>

    <!-- Display loading -->
    <div v-else class="box b-shdw-3 level">
      <div class="level-item">
        <div class="has-text-centered">
          Calculating your quote...
          <div class="loader1 level"></div>
        </div>
      </div>
    </div>

    <age-verification-modal :ageVerificationModalActive="ageVerificationModalActive" :policy-type="quoteData.policyType"
                            @modalActive="modalActive"/>
  </div>

</template>

<script>
import QuotePolicyTypeSelector from "./QuotePolicyTypeSelector";
import QuoteAreaSelector from "./QuoteAreaSelector";
import QuoteEndorsements from "./QuoteEndorsements";
import QuoteActivitySelector from "./QuoteActivitySelector";
import QuoteDatesSelector from "./QuoteDatesSelector";
import QuoteTotalTripCost from "./QuoteTotalTripCost";
import QuoteRelationshipSelector from "./QuoteRelationshipSelector";
// import QuoteMedicalScreeningNumber from "./QuoteMedicalScreeningNumber";
import QuotePassengers from "./QuotePassengers";
import QuoteEmail from "./QuoteEmail";
// import QuoteStudentCover from "./QuoteStudentCover";
import QuotePromoCodeInput from "./QuotePromoCodeInput";
import {mapActions, mapGetters} from 'vuex';
import {policyAPI} from "../../../mixins/policy-api";
import AgeVerificationModal from "./AgeVerificationModal.vue";

export default {
  name: "QuoteForm",
  data() {
    return {
      ageVerificationModalActive: false
    }
  },
  props: {
    quoteData: {type: Object, required: true},
    quoteFn: {type: Function, required: true},
    content: {required: true},
    covidPopup: {type: Object}
  },
  computed: {
    ...mapGetters({
      policySystemErrors: 'policySystemErrors',
      policyCustomerErrors: 'policyCustomerErrors',
      affiliation: 'affiliation'
    })
  },
  mixins: [policyAPI],
  components: {
    QuotePolicyTypeSelector,
    QuoteAreaSelector,
    QuoteEndorsements,
    QuotePassengers,
    QuoteActivitySelector,
    QuoteDatesSelector,
    QuoteTotalTripCost,
    QuoteRelationshipSelector,
    //QuoteMedicalScreeningNumber,
    QuoteEmail,
    QuotePromoCodeInput,
    //QuoteStudentCover
    AgeVerificationModal,
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      resetError: 'resetError'
    }),
    modalActive(emittedValue) {
      this.ageVerificationModalActive = emittedValue;
    },
  },
  watch: {
    policySystemErrors: function () {
      if (this.policySystemErrors === "age is out of bounds for this policy type") {
        this.ageVerificationModalActive = true;

        this.resetError();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#quote-form {
  padding: 30px 40px 20px 40px;

  .field {
    margin-top: 20px;
  }

  hr {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h4 {
    padding-bottom: 7px;
    border-bottom: $c2g_orange 4px solid;
    margin-bottom: 5px;
    font-size: 28px;
  }

  h5 {
    font-style: bold;
    color: $c2g_orange;
    font-size: 22px;
    font-weight: 1000;
  }
}

#covid-popup-button {
  margin: 10px auto;
  width: 200px;
}

.message {
  background-color: white;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid;
  border-color: $c2g_orange;
}

ul {
  text-align: center;
  width: 100%;
}

.tick-img {
  width: 15px;
}
</style>