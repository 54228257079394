<template>
  <section>
    <!-- Message modal -->
    <b-modal :active.sync="ageVerificationModalActive" :can-cancel="false" aria-modal aria-role="dialog" has-modal-card
             scroll="keep" trap-focus>
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-centered has-text-weight-bold">{{ ageVerificationPolicyTypeName }} Policy
            age limit exceeded</p>
        </header>
        <section class="modal-card-body">
          <p>
            The details entered fall outside {{ ageVerificationPolicyTypeName }} Policy parameters.
            {{ ageVerificationPolicyTypeName }} policy holders can only be insured up to the age of
            {{ ageVerificationMaxAge }}. However, we would be happy to offer you a Single Trip policy for your travel
            needs. We apologise for any inconvenience.
          </p>
        </section>
        <footer class="modal-card-foot level">
          <button class="button level-item" type="button" @click="onClick">Close</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: "AgeVerificationModal",
  props: ['ageVerificationModalActive', 'policyType'],
  data() {
    return {"ageVerificationPolicyTypeName": "Unknown", "ageVerificationMaxAge": 0};
  },
  watch: {
    policyType: function (val) {
      switch (val) {
        case "AT":
          this.ageVerificationMaxAge = 79;
          this.ageVerificationPolicyTypeName = "Annual";
          break;
        case "LS":
          this.ageVerificationMaxAge = 64;
          this.ageVerificationPolicyTypeName = "Longstay";
          break;
      }
    }
  },
  methods: {
    onClick() {
      this.$emit('modalActive', false);
    }
  }
}
</script>

<style scoped>
.error {
  color: red;
}
</style>